import { TechSolution, UserAccessSettingsV2 } from '../util/api/pc'
import { Steps } from './Steps'
import { Statuses, UserAccessContext } from './UserAccessContext'
import { PLATFORM_ACCESS_TYPE } from './consts'
import { IDLocker } from './idLocker/IDLocker'
import { Jira } from './jira/Jira'
import { CustomUrl } from './snow/CustomUrl'
import { Snow } from './snow/Snow'
import { useRef, useState } from 'react'

export interface AccessResolverProps {
  accessToken: string
  techSolutionData: TechSolution
  navigate?: (href: string) => void
}

export const AccessResolver = ({
  accessToken,
  techSolutionData,
  navigate,
}: AccessResolverProps): JSX.Element | null => {
  const {
    id: techSolutionId,
    userAccessSettingsV2,
    accessType,
    slug,
    url: techSolutionUrl,
    name,
  } = techSolutionData
  const [statuses, setStatuses] = useState<Statuses>({})
  const modalIsVisible = useRef<boolean>(false)
  const portalRef = useRef<HTMLDivElement>(null)
  const accessMethodsCount = [
    !!userAccessSettingsV2?.idLocker,
    !!userAccessSettingsV2?.jira,
    !!userAccessSettingsV2?.snow,
  ].filter(Boolean).length

  const renderTechSolutionAccessContent = () => {
    switch (accessType) {
      case PLATFORM_ACCESS_TYPE.steps:
        return <Steps slug={slug} navigate={navigate} />
      case PLATFORM_ACCESS_TYPE.link:
        return (
          <CustomUrl
            techSolutionUrl={techSolutionUrl}
            navigate={navigate}
            techSolutionName={name}
          />
        )
      case PLATFORM_ACCESS_TYPE.integrated:
      case PLATFORM_ACCESS_TYPE.divested:
      default:
        return null
    }
  }

  const accessConfigResolver = ({ idLocker, jira, snow }: UserAccessSettingsV2) => {
    const accessFlowComponents = {
      idlocker: <IDLocker accessToken={accessToken} techSolutionId={techSolutionId} />,
      jira: <Jira accessToken={accessToken} techSolutionId={techSolutionId} />,
      snow: <Snow accessToken={accessToken} techSolutionId={techSolutionId} />,
    }

    // Access Points combinations
    if (idLocker && snow && jira) {
      return accessFlowComponents.idlocker
    }
    if (idLocker && snow) {
      return accessFlowComponents.idlocker
    }
    if (idLocker && jira) {
      return accessFlowComponents.idlocker
    }
    if (jira && snow) {
      return accessFlowComponents.jira
    }
    if (idLocker) {
      return accessFlowComponents.idlocker
    }
    if (jira) {
      return accessFlowComponents.jira
    }
    if (snow) {
      return accessFlowComponents.snow
    }
  }

  return (
    <>
      {userAccessSettingsV2 && (
        <UserAccessContext.Provider
          value={{
            ...userAccessSettingsV2,
            modalIsVisible,
            statuses,
            portalRef,
            accessMethodsCount,
            setStatuses,
          }}
        >
          {accessConfigResolver(userAccessSettingsV2)}
        </UserAccessContext.Provider>
      )}
      {renderTechSolutionAccessContent()}
      {/* Styles to ignore grid spacing for this div */}
      <div style={{ display: 'contents' }} ref={portalRef} />
    </>
  )
}

import { Contact, TechSolution } from '../../util/api/pc'
import { SlackIcon } from '../SlackIcon'
import { Owner } from '../actionComponents/Owner'
import styles from '../homePage.styl'
import { Button, Label, Link, Text, Tooltip } from '@nike/eds'

// based on ContactType from '../util/api/pc'
export const CONTACT_TYPE = {
  github: 'GITHUB',
  slack: 'SLACK',
  pagerduty: 'PAGERDUTY',
} as const

interface ContactsProps extends Pick<TechSolution, 'owningTeamName'> {
  contacts: Contact[]
  owningTeam: string | null
  devportalUrl: string
}

interface GroupedContacts {
  github: Contact[]
  slack: Contact[]
  pagerduty: Contact[]
}

const groupContacts = (contacts: Contact[]) => {
  const groupedContacts: GroupedContacts = {
    github: [],
    slack: [],
    pagerduty: [],
  }
  contacts.forEach((contact) => {
    const { type, link } = contact
    if (link?.url) {
      if (type === CONTACT_TYPE.github) groupedContacts.github.push(contact)
      if (type === CONTACT_TYPE.slack) groupedContacts.slack.push(contact)
      if (type === CONTACT_TYPE.pagerduty) groupedContacts.pagerduty.push(contact)
    }
  })
  return groupedContacts
}

export const Contacts = ({
  contacts,
  owningTeamName,
  owningTeam,
  devportalUrl,
}: ContactsProps): JSX.Element => {
  const { github, slack, pagerduty } = groupContacts(contacts)

  return (
    <>
      <div className={styles.ownerRow}>
        <Text font='title-6'>Managed By</Text>
        <div className={styles.fullCard}>
          <Owner
            devportalUrl={devportalUrl}
            owningTeam={owningTeam}
            owningTeamName={owningTeamName}
          />
        </div>
      </div>

      {!!slack.length && (
        <div className={styles.section}>
          <Label font='title-6'>Slack Channels</Label>
          <div className={styles.contact}>
            {slack.map(({ link }) => (
              <div className={styles.card} key={`${link.text}-${link.url}`}>
                <Text font='subtitle-1'>{link.text}</Text>
                <Tooltip
                  isDark
                  label='Open Slack Channel'
                  role='tooltip'
                  aria-hidden='true'
                  bodySlot={<div className={styles.tooltip}>Open Slack Channel</div>}
                >
                  <a rel='noreferrer' target='_blank' href={link.url}>
                    <Button aria-label='Link to Slack' variant='secondary'>
                      <SlackIcon />
                    </Button>
                  </a>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!github.length && (
        <div className={styles.section}>
          <Label font='title-6'>Github</Label>
          <div className={styles.contact}>
            {github.map(({ link }, i) => (
              <div className={styles.card} key={`${link.url}-${i}`}>
                <Link rel='noreferrer' target='_blank' href={link.url}>
                  {link.url}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!pagerduty.length && (
        <div className={styles.pagerduty}>
          <Label font='title-6'>Pagerduty</Label>
          {pagerduty.map(({ link }, i) => (
            <div className={styles.fullCard} key={`${link.url}-${i}`}>
              <Link rel='noreferrer' target='_blank' href={link.url}>
                {link.url}
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
